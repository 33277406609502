.navBarItem {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-decoration: none;
    color: black;
    font-size: 18px;
    font-weight: 400;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-family: 'Inter';
  } 
.navBarItem:hover {
  background-color: #4BBEB9;
}
.navBarBg {
    background-color: #C0BFBD;
    width: 100%;
    height: 30px;
    flex-direction: row;
    display: flex;
    align-items: center;
}
.line {
  background-color: black;
  height: 1px;
}

.dropdownItem{
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-decoration: none;
  color: black;
  font-size: 18px;
  font-weight: 400;
  background-color: #e3e3e3;
  border: 0.5px solid black;
  cursor: pointer;
  font-family: 'Inter';
  width: 120px;
  text-align: left;
}

.dropdownItem:hover {
  background-color: #4BBEB9;
}

.sticky {
  position: sticky;
  top: 0px;
  z-index: 9999;
}

.logo {
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
  background-color: transparent;
}
.logo:hover {
  background-color: #4BBEB9;
}

.header {
  background-color: #ECECEC;
  height: 1.5vw;
  flex-direction: row;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid black;
  cursor: pointer;
}

.headerTitle {
  color: black;
  font-size: 14px;
  font-weight: 400;
} 

.exitButton {
  color: black;
  font-size: 12px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  height: 1.5vw;
}

.folder {
  text-align: left;
  background-color: white;
  height: 22vw;
  width: 28vw;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-top: none;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 6%;
  right: 2%;
}

.folder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  padding-right: 3vw;
  cursor: pointer;
}

.folder-text {
  text-align: center;
  padding: 2px;
  margin-top: 5px;
}

.folder-text:hover {
  background-color: #4BBEB9;
  border-radius: 6px;
  padding: 2px;
}

.notification-container {
  background-color: white;
  width: 370px;
  height: 80px;
  position: absolute;
  right: 15px;
  top: 20px;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5px;
  z-index: 100;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.notification-exit {
  display: flex;
  align-items: center;
  border-radius: 100%;
  position: absolute;
  background-color: #e3e3e3;
  width: 25px;
  height: 25px;
  left: -10px;
  top: -10px;
  justify-content: center;
  border: 1px solid black;
}

.notification-exit:hover {
  background-color: #4BBEB9;
  cursor: pointer;
}

.notification-exit-text {
  font-size: 12px;
  font-weight: 600;
}

.notification-info {
  padding-right: 10px;
  padding-left: 2px;
  object-fit: contain;
  max-height: 40%;
}