.video-container {
    text-align: left;
    background-color: white;
    height: 40vw;
    width: 58vw;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-top: none;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: absolute;

    display: flex;
    flex-direction: column;
}

.visit-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding-right: 20px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-decoration: none;
    color: black;
    font-size: 18px;
    font-weight: 400;
    background-color: #4BBEB9;
    cursor: pointer;
    font-family: 'Inter';
}

.subtitle {
    padding: 10px;
    margin-bottom: 0px;
    margin-top: -10px;
    font-size: 16px;
    font-weight: 550;
}