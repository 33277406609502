@font-face {
  margin: 0;
  font-family: 'Inter';
  src: url('./assets/Inter.ttf') format('truetype');
}

@font-face {
  margin: 0;
  font-family: 'SpaceGrotesk';
  src: url('./assets/SpaceGrotesk.ttf')  format('truetype');
}

@font-face {
  margin: 0;
  font-family: 'CabinetGrotesk';
  src: url('./assets/CabinetGrotesk-Variable.ttf')  format('truetype');
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  user-select: none;
}