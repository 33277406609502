.title {
    width: 24vw;
    height: 7vw;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-top: none;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
}

.title-text {
    font-family: 'CabinetGrotesk';
    font-size: 2vw;
    font-weight: 700;
    margin-top: 1.25vw;
    margin-bottom: 0px;
}

.skills-container {
    text-align: left;
    background-color: white;
    height: 35vw;
    width: 58vw;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-top: none;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 30%;
    top: 20%;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 3 columns */
    gap: 0.5vw;
    padding-right: 40px;
    padding-left: 40px;
    align-items: center;
  }

  .grid-title {
    grid-column: 1 / span 6;
    margin-bottom: 0;
    margin-top: 0.5vw;
    font-size: 1.25vw;
  }
  
  .grid-item {
    position: relative;
  }

  .tooltip {
    background-color: '#ECECEC';
    color: black;
    border: 1px solid black;
  }
  
  .grid-item img {
    max-width: 50%;
    height: auto;
  }
  
  .black-line {
    grid-column: 1 / span 6;
    border-top: 1px solid black;
  }
  