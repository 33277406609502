.job-card {
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-top: none;
    border-radius: 5px;
    text-align: left;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #FBD6D0;
    width: 25vw;   
}

.heading1 {
    margin-bottom: 0px;
    margin-left: 1vw;
    font-weight: 500;
}

.heading2 {
    margin-top: 0px;
    margin-left: 1vw;
    font-weight: 400;
}

.description {
    margin-left: 1vw;
    margin-right: 1vw;
    font-size: 2vh;
}

.company-logo {
    max-width: 90%;
    max-height: 3vw;
    margin-top: 2vw;
    margin-left: 1vw;
}